<template>
    <div class="uploadmain">
        <el-upload
            class="image-uploader"
            :http-request="uploadFile"
            :headers="{'Authorization': accessToken}"
            accept=".png,.jpg,.jpeg"
            action="tmp"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
            :on-progress="uploadProcess"
            :on-preview="handlePictureCardPreview">

            <div class="img-box" v-if="uploadFlag===false && currImageUrl">
                <img
                    class="image" :style="{width: width+ 'px', height: height+ 'px'}"
                    :src="currImageUrl" alt=""
                >
                <span class="item-actions">
                    <span class="item-preview" @click.stop="handlePictureCardPreview('')">
                      <i class="el-icon-view"></i>
                    </span>
                    <span class="item-delete" @click.stop="handleRemove()">
                      <i class="el-icon-delete"></i>
                    </span>
              </span>
            </div>

<!--            <img v-if="uploadFlag===false && currImageUrl" :src="currImageUrl" class="image" :style="{width: width+ 'px', height: height+ 'px'}">-->
            <el-progress v-else-if="currImageUrl==='' && uploadFlag == true" type="circle" :percentage="uploadPercent"></el-progress>
            <i v-else class="el-icon-plus image-uploader-icon" :style="{width: width+ 'px', height: height+ 'px', lineHeight: height+ 'px'}"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
            <img width="100%" :src="currImageUrl" alt="">
        </el-dialog>
        <div style="text-align: center">
            <p style="color: red; line-height: 16px">{{text}}</p>
            <p v-show="subtext" style="color: red; line-height: 16px">{{subtext}}</p>
        </div>
    </div>
</template>

<script>
    import Compressor from "compressorjs";

    export default {
        props: {
            height: {
                type: Number,
                default: 140
            },
            width: {
                type: Number,
                default: 140
            },
            index: {
                type: Number,
                default: 0
            },
            imageUrl: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            subtext: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
                uploadUrl: process.env.VUE_APP_API_HOST + 'system/file/uploadFile',
                dialogVisible: false,
                uploadFlag: false,
                uploadPercent: 0,
                currImageUrl: ''
            }
        },
        watch: {
            imageUrl: {
                handler (val) {
                    this.currImageUrl = val
                },
                deep: true,
                immediate: true
            },
        },
        methods: {
            uploadFile: function (f) {
                let that = this;
                // eslint-disable-next-line no-new
                new Compressor(f.file, {
                    maxWidth: 1024,   // 压缩完成的宽度，高度会自适应
                    quality: 0.8, // 压缩的质量，不加上面的宽度话压缩效果很不理想，体积基本没有变化，一定要加个宽或者高度
                    success: res => {      // 箭头函数的形势去除success this指向内部函数的
                        // file = new File([res], res.name, {  // 返回的结果是个blob对象，js内置函数将其3转为file对象
                        //     type: res.type,
                        //     lastModified: Date.now()
                        // });
                        // console.log("图片压缩后res", res)
                        // console.log("图片压缩后file", file.size)
                        let reader = new FileReader();
                        reader.readAsDataURL(res);
                        reader.onload = function (e) {
                            // e.target.result
                            console.log("e.target.result", e.target.result)
                            let fdata = {
                                file: e.target.result
                            }
                            let url = "system/file/uploadFileBase64";
                            // 保存数据
                            that.apis.postForm(url, fdata)
                                .then((res) => {
                                    if (res.code === 1) {
                                        console.log(res.data)
                                        that.success(res.data);
                                    } else {
                                        this.$message({
                                            message: res.msg,
                                            showClose: true,
                                            offset: 200,
                                            type: 'error'
                                        });
                                    }
                                }).catch(err => {
                                console.log(err);
                            });
                        }
                    },
                    error (err) {
                        that.$message({ message: '上传失败', showClose: true, offset: 200, type: 'error' });
                        console.log(err)
                    }
                });

                // let formData = new FormData();
                // formData.append('file', f.file);
                // let url = "system/file/uploadFile";
                // // 保存数据
                // this.apis.postFile(url, formData)
                //     .then((res) => {
                //         if (res.code === 1) {
                //             console.log(res.data)
                //             this.success(res.data);
                //         } else {
                //             this.$message({
                //                 message: res.msg,
                //                 showClose: true,
                //                 offset: 200,
                //                 type: 'error'
                //             });
                //         }
                //     }).catch(err => {
                //     console.log(err);
                // });
            },
            uploadSuccess(res, file) {
                // console.log(res) savepath
                this.success(res.data);
            },
            success(data) {
                // console.log(res) savepath
                this.uploadFlag = false;
                this.uploadPercent = 0;
                this.currImageUrl = data.webPath;
                this.$emit("callback", data.webPath, data.savepath, this.index)
            },
            beforeUpload(file) {

                let types = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png'];
                const isImage = types.includes(file.type);
                const isLt2M = true; // file.size / 1024 / 1024 < 2;
                if (!isImage) {
                    this.$message({
                        message: '只能上传图片格式!',
                        showClose: true,
                        offset: 150,
                        type: 'error'
                    });
                }
                // if (!isLt2M) {
                //     this.$message({
                //         message: '上传头像图片大小不能超过 2MB!',
                //         showClose: true,
                //         offset: 150,
                //         type: 'error'
                //     });
                // }
                return isImage && isLt2M;
            },
            uploadProcess(event, file, fileList){
                this.uploadFlag = true;
                this.uploadPercent = parseInt(file.percentage.toFixed(0), 0);
            },
            handlePictureCardPreview(file) {
                this.dialogVisible = true;
            },
            handleRemove() {
                this.currImageUrl = "";
                this.$emit("callback", '', '', this.index)
            },
        },
        mounted() {
            this.$nextTick(function () {
            });
        }
    }
</script>

<style lang="scss" scoped>
    .uploadmain {
        height: 100%;
        width: 100%;
    }
    ::v-deep .image-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
        .image-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 140px;
            height: 140px;
            line-height: 140px;
            text-align: center;
        }
        .image {
            width: 140px;
            height: 140px;
            display: block;
        }
        .item-actions {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            cursor: default;
            text-align: center;
            color: #fff;
            opacity: 0;
            font-size: 20px;
            background-color: rgba(0,0,0,.5);
            transition: opacity .3s;
            display: none;
            cursor: pointer
            //visibility: hidden;
        }
        .img-box:hover .item-actions{
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
</style>

